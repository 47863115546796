import React, { useEffect, useRef, useState } from 'react';
import {
  Map,
  Marker,
  ScaleControl,
  TileLayer,
  WMSTileLayer,
  Popup,
} from 'react-leaflet';
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;
import _ from 'lodash';

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const selectMap = (mapName) => {
  const maps = {
    dark: 'http://a.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
    topography: 'https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}{r}.png',
    default: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
    satellite: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  }

  return maps[mapName] || maps.default
};

const CONFIG_DEFAULT = {
  lat: -14.392118,
  lng: -56.250000,
  zoom: 4,
  minZoom: 4,
  maxZoom: 17
};

function fetchLayerPointData(layerId, bboxString) {
  const geoserverBaseUrl = `https://geoserver.ecostage.com.br/geoserver/wwf/ows?`;
  const wfsParams = [
    'service=WFS',
    'version=2.0.0',
    'request=GetFeature',
    `typeNames=${ layerId }`,
    'count=20',
    'outputFormat=application/json',
    'srsName=EPSG:4326',
    `bbox=${ bboxString },EPSG:4326`,
  ];

  return fetch(`${ geoserverBaseUrl }${ wfsParams.join('&') }`)
    .then((resp) => resp.json());
}

export default function MapCanvas({
  activeTerritoryType,
  activeTerritory,
  activeYear,
  activePoint,
  layers,
  selectedMap,
  onChange = () => {},
}) {
  let mapRef = useRef();
  const years = _.split(activeYear, '-');
  const firstYear = Number(_.first(years));
  const lastYear = Number(_.last(years));
  const yearParam = `YearPADDD >= ${ firstYear } and YearPADDD <= ${ lastYear }`;

  useEffect(() => {
    if (activeTerritory && mapRef.current) {
      const bbox = _.get(activeTerritory, 'feature.bbox');
      const parsedBbox = [[bbox[1], bbox[0]], [bbox[3], bbox[2]]];

      mapRef.current.leafletElement.flyToBounds(parsedBbox, { padding: [50, 50] });
    }
  }, [activeTerritory]);

  const handleMapClick = (event) => {
    const { lat, lng } = event.latlng;
    const coordinates = [lat, lng];
    const { x, y } = event.layerPoint;
    const point1 = { x: x - 1, y: y - 1 };
    const point2 = { x: x + 1, y: y + 1 };

    const parsedPoint1 = mapRef.current.leafletElement.layerPointToLatLng(point1);
    const parsedPoint2 = mapRef.current.leafletElement.layerPointToLatLng(point2);

    const parsedBBox = [
      _.get(parsedPoint1, 'lng'),
      _.get(parsedPoint2, 'lat'),
      _.get(parsedPoint2, 'lng'),
      _.get(parsedPoint1, 'lat'),
    ];

    const bboxString = _.join(parsedBBox, ',');

    console.log('map click 1', lat, lng, point1, point2);
    console.log('map click 2', bboxString);

    onChange({
      action: 'changePointData',
      data: {
        coordinates: [lat, lng],
        loadingFeatures: true,
        features: null
      }
    });
    // fetchLayerPointData('wwf:padddtracker-layer', bboxString)
    fetchLayerPointData('wwf:padddtracker-layer', bboxString)
      .then((data) => {
        console.log('data 01', data)
        const features = _.get(data, 'features');

        onChange({
          action: 'changePointData',
          data: {
            coordinates: [lat, lng],
            loadingFeatures: false,
            features
          }
        });
      });
    // fetchLayerPointData('wwf:padddtracker-layer', '-63.265440724790096,-9.557584617286922,-62.710631154477596,-9.002775046974422')
    //   .then((data) => console.log('data 02', data));
  };

  const renderTileLayer = () => {
    const url = selectMap(selectedMap);

    return (
      <TileLayer
        key="base-map-tile-layer"
        url={ url }
        zIndex={ 50 }
      />
    );
  };

  // const renderBaseLayers = () => {
  //   return _.map(activeBaseLayers, (id, index) => {
  //     const layerData = _.find(baseLayers, { id });

  //     return (
  //       <WMSTileLayer
  //         key={ `additional-layer-${ id }` }
  //         transparent
  //         format="image/png"
  //         zIndex={ 300 + index }
  //         { ...(_.get(layerData, 'props')) }
  //       />
  //     );
  //   })
  // };

  const renderDefaultLayer = () => {
    const padddLayers = _.filter(layers, (layer) => {
      return layer.show && _.get(layer, 'props.layers') === 'wwf:padddtracker-layer';
    });
    console.log('padddLayers', padddLayers);

    if (!_.isEmpty(padddLayers)) {
      return null;
    }

    return (
      <WMSTileLayer
        transparent
        zIndex={ 300 }
        url="https://geoserver.ecostage.com.br/geoserver/wwf/ows"
        format="image/png"
        layers="wwf:padddtracker-layer"
        cql_filter={ yearParam }
      />
    );
  };

  const renderLayers = () => {
    const visibleLayers = _.filter(layers, { show: true });
    console.log('visibleLayers', visibleLayers);

    return _.map(visibleLayers, (layer, index) => {
      const props = _.get(layer, 'props');
      const cqlFilter = _.get(props, 'cql_filter');
      let customCQLFilter = {};

      if (cqlFilter) {
        customCQLFilter = { cql_filter: cqlFilter };

        if (activeTerritory) {
          customCQLFilter.cql_filter += ` and ${ activeTerritoryType } = '${ _.get(activeTerritory, 'id') }'`
        }

        if (activeYear) {
          customCQLFilter.cql_filter += ` and ${ yearParam }`;
        }
      }

      console.log('customCQLFilter', customCQLFilter);

      return (
        <WMSTileLayer
          transparent
          key={ `layer-${ _.get(layer, 'id') }` }
          zIndex={ _.get(layer, 'baseZIndex') + index }
          format="image/png"
          opacity={  _.get(layer, 'opacity') }
          { ...props }
          { ...customCQLFilter }
        />
      );
    })
  };
  
  return (
    <Map
      ref={ mapRef }
      center={ [CONFIG_DEFAULT.lat, CONFIG_DEFAULT.lng] }
      zoom={ CONFIG_DEFAULT.zoom }
      minZoom={ CONFIG_DEFAULT.minZoom }
      maxZoom={ CONFIG_DEFAULT.maxZoom }
      style={{ height: '100%' }}
      onClick={ handleMapClick }
    >
      { renderTileLayer() }
      { renderDefaultLayer() }
      { renderLayers() }
      {/* <WMSTileLayer
        transparent
        zIndex={ 300 }
        url="https://geoserver.ecostage.com.br/geoserver/wwf/ows"
        format="image/png"
        layers="wwf:padddtracker-layer"
        cql_filter={ cql }
        // cql_filter="EnactedPro = 'Proposto' and EventType = 'Recategorização'"
        // cql_filter="EnactedPro = 'Proposto' and EventType = 'Extinção'"
      /> */}
      { activePoint &&
        <Marker position={ activePoint } />
      }
    </Map>
  );
}
