import React from 'react'
import { isArray, has } from 'lodash'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'

import Accordion from '../../../shared/components/Accordion'
import './LayerInfo.css'

const aliases = {
  'ucstodas_no_event': 'Área protegida',
  'ucstodas_downsize_enacted': 'Área protegida (Redução Promulgada)',
  'ucstodas_downgrade_enacted': 'Área protegida (Recategorização Promulgada)',
  'ucstodas_degazette_enacted': 'Área protegida (Extinção Promulgada)',
  'ucstodas_downsize_proposed': 'Área protegida (Redução Proposta)',
  'ucstodas_downgrade_proposed': 'Área protegida (Recategorização Proposta)',
  'ucstodas_degazette_proposed': 'Área protegida (Extinção Proposta)',
  'ucstodas': 'Áreas Protegidas',
  'UC_Jan_2012_Urbana': 'Urbana',
  'UC_Jan_2012_Rodovias_Planejadas_UC': 'Rodovias Planejadas',
  'UC_Jan_2012_Rodovias_Instaladas_UC': 'Rodovias Instaladas',
  'UC_Jan_2012_Mineracao': 'Mineração',
  'UC_Jan_2012_Linha_Transmissao_Planejada': 'Linha Transmissão Planejada',
  'UC_Jan_2012_Linha_Transmissao_Instalada': 'Linha Transmissão Instalada',
  'UC_Jan_2012_Grau_de_Ameaca': 'Grau de Ameaça',
  'UC_Jan_2012_Geracao_Energia_Planejado': 'Geração Energia Planejado',
  'UC_Jan_2012_Geracao_Energia_Instalada': 'Geração Energia Instalada',
  'UC_Jan_2012_Desmatamento': 'Desmatamento',
  'UC_Jan_2012_Assentamentos': 'Assentamentos',
  'UC_Jan_2012_Agropecuaria': 'Agropecuária',
}

const accordionStyle = {
  title: { color: '#555' },
  content: {
    padding: '10px 15px'
  }
}

const LayerInfo = ({ layerData, onChange = () => {} }) => {
  const emitter = (action, data = {}) => onChange({ action, data })
  const reportButton = (shapeId) => (
    <Grid container item xs={ 12 } justify="center" style={{ margin: '5px 0' }}>
      <Button
        variant='contained'
        className="reportButton"
        color='primary'
        onClick={ () => emitter('layerInfoReportPaddd', { shapeId }) }
      >Reportar evento PADDD</Button>
    </Grid> )

  return (
    <section className="layerInfoPopup">
      { (isArray(layerData) && layerData.length > 0) &&
      <>
        <header>
          <strong>DADOS DA CAMADA</strong>
          <Button onClick={ () => emitter('layerInfoClose') }><CloseIcon /></Button>
        </header>

        <section className="layerInfoPopupBody">
          { (isArray(layerData) && layerData.length === 0 && !shapeId) &&
            <strong>Nenhuma informação encontrada para essa camada</strong> }

          { layerData.map((i, k) => {
            if(i.layer.indexOf('ucstodas') !== -1) {
              const item = has(i, 'features') ? i.features[0] : {};
              const { nome_uc1, all_name, notes, year_pa_gaze, causes } = item.properties;
              const shapeId = item.id.split('.').length > 0 ? item.id.split('.')[1] : null;
              const layerName = i.layer.replace(`_${ process.env.REACT_APP_ENV }`, '');
              let yearPaddd = item.properties.year_paddd;
              
              if (yearPaddd === 0) {
                  yearPaddd = undefined;
              }
              
              return(
                <Accordion
                  key={ `layerInfoUcs-${k}` }
                  classes={ accordionStyle }
                  sessionTitle={ aliases[layerName] ? aliases[layerName] : 'Área Protegida' }>
                  <ul>
                    <li><b>Nome</b><br /> { all_name || nome_uc1 }</li>
                    { notes && <li><b>Observações</b><br /> { notes }</li> }
                    { year_pa_gaze && <li><b>Data de criação</b><br /> { year_pa_gaze }</li> }
                    { yearPaddd && <li><b>Data PADDD</b><br /> { yearPaddd }</li> }
                    { causes && <li><b>Causas</b><br /> { causes }</li> }
                  </ul>
                  { reportButton(shapeId) }
                </Accordion> )
            }

            return (
              <Accordion
                initOpened={ k === 0 ? true : false }
                classes={ accordionStyle }
                key={ `layerInfo-${k}` }
                sessionTitle={ aliases[i.layer] ? aliases[i.layer] : 'Camada' }>
                { i.features.map((f, fk) => {
                  const { ANO_CRIA6, CATEGORI3, NOME_ORG12, NOME_ORG_1, NOME_UC1, NOME_UC1_1 } = f.properties
                  return (
                    <ul key={ `layerInfoProps-${fk}` }>
                      <li><b>Nome</b><br /> { NOME_ORG12 || NOME_ORG_1 } { NOME_UC1 || NOME_UC1_1 }</li>
                      <li><b>Ano criação</b><br /> { ANO_CRIA6 }</li>
                      <li><b>Categoria</b><br /> { CATEGORI3 }</li>
                    </ul>
                  )}) }
              </Accordion>
            )
          }) }
        </section>
      </> }
    </section>)
}

export default LayerInfo
