import React, { useState } from 'react'
import _ from 'lodash'

import Slider from '@material-ui/core/Slider'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'

import './MapTimeline.css'

const MARKS = [
  { value: 1959, label: '1959-1969' },
  { value: 1970, label: '1970-1979' },
  { value: 1980, label: '1980-1989' },
  { value: 1990, label: '1990-1999' },
  { value: 2000, label: '2000-2009' },
  { value: 2010, label: '2010-2019' },
  { value: 2020, label: '2020-2029' },
];

function ValueLabelComponent(props) {
  const { children, open, value } = props;
  const item = _.find(MARKS, { value });

  return (
    <Tooltip
      arrow
      open={ open }
      placement="top"
      title={ _.get(item, 'label') }
      enterDelay={ 1000 }
    >
      { children }
    </Tooltip>
  );
}

const MapTimeline = ({
  onChange
}) => {
  const [currentValue, setCurrentValue] = useState([_.first(MARKS).value, _.last(MARKS).value]);

  const getValueYearStr = (value) => {
    const firstItem = _.find(MARKS, { value: _.first(value) });
    const lastItem = _.find(MARKS, { value: _.last(value) });
    const firstYearLimit = _.first(_.split(_.get(firstItem, 'label'), '-'));
    const lastYearLimit = _.last(_.split(_.get(lastItem, 'label'), '-'));

    return `${ firstYearLimit }-${ lastYearLimit }`;
  };

  const handleChange = (_event, newValue) => {
    setCurrentValue(newValue)
  };

  const handleChangeComitted = (_event, value) => {
    const yearStr = getValueYearStr(value);

    onChange({ action: 'changeYear', value: yearStr });
  };

  const currentYearStr = getValueYearStr(currentValue);

  return (
    <div className="map-timeline">
      <span className="map-timeline-year-info">
        <span>Ano selecionado: </span>
        <b>{ currentYearStr }</b>
        <Tooltip title="A barra apresenta apenas os anos com eventos de PADDD">
          <InfoIcon />
        </Tooltip>
      </span>
      <Slider
        color="secondary"
        step={ null }
        min={ _.first(MARKS).value }
        max={ _.last(MARKS).value }
        marks={ MARKS }
        value={ currentValue }
        defaultValue={ 2020 }
        ValueLabelComponent={ ValueLabelComponent }
        onChange={ handleChange }
        onChangeCommitted={ handleChangeComitted }
      />
    </div>
  )
}

export default MapTimeline
