import jwtDecode from 'jwt-decode'
import { isNil } from 'lodash'

const setToken = (token, permanent = false) => {
  if (permanent) return localStorage.setItem('token', token)

  return sessionStorage.setItem('token', token)
}
const getToken = () => {
  if (!isNil(sessionStorage.getItem('token'))) return sessionStorage.getItem('token')
  if (!isNil(localStorage.getItem('token'))) return localStorage.getItem('token')

  return ''
}
const removeToken = () => {
  sessionStorage.removeItem('token')
  localStorage.removeItem('token')
}
const decodeToken = () => {
  const token = getToken()
  if (!isNil(token)) {
    try {
      return jwtDecode(token)
    } catch (error) {
      return { error: 'Token inválido' }
    }
  }

  return null
}

export { setToken, getToken, removeToken, decodeToken }
