import gql from 'graphql-tag'

export const CREATE_ISSUE = gql`
mutation(
  $title: String!, 
  $description: String!, 
  $email: String!, 
  $shapeId: String!
) {
  createIssue(
    title: $title, 
    description: $description, 
    email: $email, 
    shapeId: $shapeId
  ) {
    success
    messages
  }
}
`
