import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ArrowDropDown from '@material-ui/icons/ArrowBackIos'

import './index.css'

const Accordion = ({ sessionTitle = '', children, classes = {}, initOpened = true }) => {
  const [ open, setOpen ] = useState(initOpened)

  return (
    <Grid container item xs={ 12 } className={ "accordion-container" }>
      <a onClick={ () => setOpen(!open) } className={ `link-header ${ open ? 'open' : '' }` }>
        <ArrowDropDown />
        <Typography style={ classes.title }>{ sessionTitle }</Typography>
      </a>
      { open &&
      <Grid container item xs={ 12 } className="accordion-body" style={ classes.content }>
        { children }
      </Grid> }
    </Grid>
  )
}
export default Accordion
