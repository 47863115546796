import React from 'react'
import PropTypes from 'prop-types'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks'

import client from '../apollo-client'
import Routes from '../Routes'

class Root extends React.Component {
  static propTypes = {
    client: PropTypes.object
  }

  render() {
    return (
      <ApolloProvider client={ client }>
        <ApolloHooksProvider client={ client }>
          <Routes />
        </ApolloHooksProvider>
      </ApolloProvider>
    )
  }
}

export default Root
