import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'

const Menu = ({ items = [] }) => {
  const item = (item, key) => {
    const style = { textDecoration: 'none' }
    if (item.link.indexOf('http') !== -1) return(<a style={ style } key={ key } href={ item.link }><Button>{ item.name }</Button></a>)

    return(<Link style={ style } onClick={ (e) => console.log(e) } key={ key } to={ item.link }><Button>{ item.name }</Button></Link>)
  }

  const menuItems = () => items.map((i, key) => item(i, key))

  return (
    <>
      { items && menuItems() }
    </>
  )
}

export default Menu