/* eslint-disable no-undef */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { debounce, isArray, get } from 'lodash'

import MapFilterForm from '../components/Home/MapFilterForm/MapFilterForm'
import MapTimeline from '../components/Home/MapTimeline'
import StatisticsBox from '../components/Home/StatisticsBox'
import MainLayout from '../app/layouts/MainLayout'
// import Map from '../components/Home/Map/Map'
import LeafletMap from '../components/Home/Map/LeafletMap'
import LayerInfo from '../components/Home/LayerInfo'
import PopupIssue from '../components/Home/PopupIssue'
import PointInfoBox from '../components/Home/PointInfoBox'
import DraggableMapLayers from '../components/Home/DraggableMapLayers/DraggableMapLayers'
import MyTab from '../shared/components/MyTab'

import MapCanvas from '../components/Home/MapCanvas';

import './Home.css'
import mapLayers from './mapLayers'

const HEADER_HEIGHT = 64;

class Home extends React.Component {
  state = {
    loading: false,
    map: 'default',
    activeTerritoryType: 'Biome',
    activeTerritory: null,
    activeYear: '1959-2029',
    activePointData: null,
    activeFeatureIdIssue: null,
    mapZoom: 5,
    mapCenter: [-9.99766, -52.90838],
    mapLayers,
    layerInfos: [],
    selectedLayerInfos: [],
    showLayerInfo: false,
    showIssuePopup: false,
    issuePopupData: {
      shapeId: 0,
    },
    showStatistics: false,
    contentHeight: window.innerHeight - HEADER_HEIGHT
  }

  updateHeight = () => {
    this.setState({ contentHeight: window.innerHeight - HEADER_HEIGHT })
  }

  debouncedUpdateHeight = debounce(this.updateHeight, 500)

  componentDidMount = () => {
    window.addEventListener('resize', this.debouncedUpdateHeight)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.debouncedUpdateHeight)
  }

  setMapLayers = (params) => {
    console.log('mapLayers before', this.state.mapLayers);
    const mapLayers = this.state.mapLayers.map((value) => {
      if (params.id === value.id) return { ...value, show: params.active, opacity: params.opacity }

      return value
    })

    console.log('mapLayers after', mapLayers);

    this.setState({ mapLayers })
  }

  debouncedSetMaps = debounce(this.setMapLayers, 500)

  actionHandlerOnChange = (params) => {
    console.log('params on actionHandlerOnChange', params);
    switch (params.action) {
      case 'changeTerritoryType':
        this.setState({ activeTerritoryType: params.id })
        break
      case 'changeTerritory':
        this.setState({ activeTerritory: params.data })
        break
      case 'changeYear':
        this.setState({ activeYear: params.value })
        break
      case 'changePointData':
        this.setState({ activePointData: params.data })
        break
      case 'changeFeatureIdIssue':
        this.setState({ activeFeatureIdIssue: params.data })
        break
      case 'selectBasemap':
        this.setState({ map: params.data })
        break
      case 'closeIssuePopup':
        this.setState({ activeFeatureIdIssue: null })
        break
      case 'shapeClick':
        const { id } = params.shape.feature
        this.setState({
          showLayerInfo: true, issuePopupData: { shapeId: id.replace('ucstodas.', '') }
        })
        break
      case 'loading':
        this.setState({ loading: params.data })
        break
      case 'showLayerInfoOnClick':
        if (
          isArray(this.state.mapLayers) &&
          isArray(this.state.layerInfos) &&
          this.state.mapLayers.length === this.state.layerInfos.length
        ) {
          this.setState({ layerInfos: [] });
        }

        this.setState({
          layerInfos: [ ...this.state.layerInfos, params.data ]
        }, () => {
          if (
            isArray(this.state.mapLayers) &&
            isArray(this.state.layerInfos) &&
            this.state.mapLayers.length === this.state.layerInfos.length
          ) {
            this.setState({
              loading: false
            }, () => {
              this.showLayerPopup()
            });
          }
        });
        break
      case 'showInfo':
        console.log('showInfo', params)
        break
      case 'centerMap':
        this.setState({
          mapCenter: [-9.99766, -52.90838],
        })
        break
      case 'opacityChange':
        this.debouncedSetMaps(params)
        break
      case 'switchChange':
        this.setMapLayers(params)
        break
      case 'layerInfoError':
        setTimeout(() => {
          this.setState({
            showLayerInfo: false
          })
        }, 3000)
        break
      case 'layerInfoReportPaddd':
        const { shapeId } = params.data
        this.setState({
          showLayerInfo: false,
          showIssuePopup: true,
          issuePopupData: { shapeId }
        })
        break
      case 'layerInfoClose':
        this.setState({
          showLayerInfo: false,
        })
        break
      case 'openStatistics':
        this.setState({ showStatistics: true })
        break
      case 'closeStatistics':
        this.setState({ showStatistics: false })
        break
      default:
        console.log(params)
        break
    }
  }

  showLayerPopup = () => {
    console.log('veio aqui showLayerPopup');
    // const findedItems = []
    // for (const value of this.state.mapLayers) {
    //   if(!value.show) continue

    //   const { className } = value.otherProps
    //   const findedLayerInfo = this.state.layerInfos.find((l) => l.layer === className)

    //   if (findedLayerInfo && findedLayerInfo.features.length === 0) continue

    //   findedItems.push(findedLayerInfo)
    // }

    // if(findedItems.length > 0) this.setState({ selectedLayerInfos: findedItems, showLayerInfo: true })
  }

  render() {
    return(
      <MainLayout>
        <Grid
          container
          item
          xs={ 12 }
          alignItems="stretch"
        >
          <Grid
            item
            xs={ 3 }
            style={{ background: '#fff', height: this.state.contentHeight, overflowY: 'scroll' }}
          >
            <MapFilterForm
              territoryType={ this.state.activeTerritoryType }
              territory={ this.state.activeTerritory }
              onChange={ this.actionHandlerOnChange }
            />
          </Grid>
  
          <Grid item xs={ 9 }>
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: 'calc(100vh - 59px)',
              }}
            >
              { this.state.loading &&
                <div id="loader">
                  <div className="overlay"></div>
                  <div className="loading">
                    <CircularProgress color="inherit" />
                  </div>
                </div> }

              {/* APENAS PARA LEMBRAR DESSA "GAMBIARRA" - Isso aqui é utilizado para controlar a visibilidade da camada */}
              {/* <style>{ this.state.mapLayers.map((l) => `.${l.otherProps.className} { display: ${ l.show ? 'block' : 'none' }; opacity: ${ l.opacity > 0 && l.opacity < 1 ? (Math.round(l.opacity * 10) / 10) : l.opacity } !important; }`) }</style> */}

              {/* <LeafletMap
                zoom={ this.state.mapZoom }
                center={ this.state.mapCenter }
                selectedMap={ this.state.map }
                layers={ this.state.mapLayers }
                onChange={ this.actionHandlerOnChange }
              /> */}

              <MapCanvas
                activeTerritoryType={ this.state.activeTerritoryType }
                activeTerritory={ this.state.activeTerritory }
                activeYear={ this.state.activeYear }
                activePoint={ _.get(this.state.activePointData, 'coordinates') }
                selectedMap={ this.state.map }
                layers={ this.state.mapLayers }
                onChange={ this.actionHandlerOnChange }
              />
              
              { this.state.showLayerInfo &&
                <LayerInfo
                  layerData={ this.state.selectedLayerInfos }
                  onChange={ this.actionHandlerOnChange }
                /> }
  
              {/* { this.state.showIssuePopup &&
                <PopupIssue
                  onChange={ this.actionHandlerOnChange }
                  shapeId={ this.state.issuePopupData.shapeId } /> } */}

              { this.state.activeFeatureIdIssue &&
                <PopupIssue
                  onChange={ this.actionHandlerOnChange }
                  shapeId={ this.state.activeFeatureIdIssue }
                />
              }

              <MapTimeline
                onChange={ this.actionHandlerOnChange }
              />
              <StatisticsBox
                activeTerritoryType={ this.state.activeTerritoryType }
                activeTerritory={ this.state.activeTerritory }              
                activeYear={ this.state.activeYear }
                layers={ this.state.mapLayers }
                isOpen={ this.state.showStatistics }
                onClose={ this.actionHandlerOnChange }
              />
              <PointInfoBox
                isOpen={ this.state.activePointData }
                loading={ _.get(this.state.activePointData, 'loadingFeatures') }
                features={ _.get(this.state.activePointData, 'features') }
                onChange={ this.actionHandlerOnChange }
              />
            </div>
          </Grid>
        </Grid>
      </MainLayout>
    )
  }
}

export default Home
