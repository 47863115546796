import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import Button from '@material-ui/core/Button'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'

import Accordion from '../../../shared/components/Accordion'
import LayerControl from '../../../shared/components/LayerControl'
import BasemapSelect from '../../../shared/components/BasemapSelect'

import './MapFilterForm.css'

const accordionStyle = { content: { padding: '0 .5rem' } }

function fetchLayerData(layerId) {
  const geoserverBaseUrl = `https://geoserver.ecostage.com.br/geoserver/wwf/wfs?`;
  const wfsParams = [
    'service=WFS',
    'version=2.0.0',
    'request=GetFeature',
    `typeNames=${ layerId }`,
    'outputFormat=application/json',
    'srsName=EPSG:4326',
  ];

  return fetch(`${ geoserverBaseUrl }${ wfsParams.join('&') }`)
    .then((resp) => resp.json());
}

const BIOME_PROPERTY_KEY = 'Bioma';
const STATE_PROPERTY_KEY = 'NM_UF';

const TerritoryFilter = ({
  territoryType,
  territory,
  onChange,
}) => {
  const [biomeFeatures, setBiomeFeatures] = useState([]);
  const [stateFeatures, setStateFeatures] = useState([]);
  const [biomeOptions, setBiomeOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  useEffect(() => {
    fetchLayerData('wwf:biomas')
      .then((data) => {
        const features = _.get(data, 'features');
        console.log('features', features);
        const options = _.map(features, (feature) => _.get(feature, `properties.${ BIOME_PROPERTY_KEY }`));

        setBiomeFeatures(features);
        setBiomeOptions(options);
      });
    fetchLayerData('wwf:estados')
      .then((data) => {
        const features = _.get(data, 'features');
        console.log('features', features);
        const options = _.map(features, (feature) => _.get(feature, `properties.${ STATE_PROPERTY_KEY }`));

        setStateFeatures(features);
        setStateOptions(options);
      });
  }, []);

  const handleTypeChange = (event) => {
    onChange({ action: 'changeTerritoryType', id: event.target.value });
    onChange({ action: 'changeTerritory', data: null });
  }

  const handleValueChange = (event) => {
    const value = event.target.value;

    if (value === 'all') {
      onChange({ action: 'changeTerritory', data: null });
    } else {
      const propertyKey = territoryType === 'Biome' ? BIOME_PROPERTY_KEY : STATE_PROPERTY_KEY;
      const features = territoryType === 'Biome' ? biomeFeatures : stateFeatures;
      const feature = _.find(features, (f) => _.get(f, `properties.${ propertyKey }`) === value);
  
      if (feature) {
        onChange({ action: 'changeTerritory', data: { id: value, feature } });
      }
    }
  }

  const options = territoryType === 'Biome' ? biomeOptions : stateOptions;

  return (
    <Fieldset title="Território">
      <RadioGroup
        name="radio-group-date-type"
        value={ territoryType }
        className="radioGroup"
      >
        <FormControlLabel
          value="Biome"
          label="Bioma"
          control={ <Radio className="radioGroupInput" size="small" /> }
          classes={ {
            label: "radioGroupLabel"
          } }
          onChange={ handleTypeChange }
        />
        <FormControlLabel
          value="State"
          label="Estado"
          control={ <Radio className="radioGroupInput" size="small" /> }
          classes={ {
            label: "radioGroupLabel"
          } }
          onChange={ handleTypeChange }
        />
      </RadioGroup>
      <Select
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={ territory ? territory.id : 'all' }
        onChange={ handleValueChange }
        className="territory-filter-select"
      >
        <MenuItem value="all">Todos</MenuItem>
        { _.map(_.sortBy(options), (option) => {
          return (
            <MenuItem key={ `territory-opt-${ option }` } value={ option }>{ option }</MenuItem>
          );
        }) }
      </Select>
    </Fieldset>
  )
}

const Fieldset = ({ children, title }) => (
  <div className="map-filter-fieldset">
    <span className="map-filter-fieldset-title">{ title }</span>
    { children }
  </div>
)

const MapFilterForm = ({
  territoryType,
  territory,
  onChange = () => {}
}) => (
  <>
    <Accordion sessionTitle="Filtros" classes={ accordionStyle }>
      <TerritoryFilter
        territoryType={ territoryType }
        territory={ territory }
        onChange={ onChange }
      />
      <Fieldset title="Proposto">
        <LayerControl
          color="#acd5d5"
          title="Redução"
          id="proposto_reducao"
          onChange={ onChange }
        />
        <LayerControl
          color="#d596d5"
          title="Recategorização"
          id="proposto_recategorizacao"
          onChange={ onChange }
        />
        <LayerControl
          color="#fabd7d"
          title="Extinção"
          id="proposto_extincao"
          onChange={ onChange }
        />
      </Fieldset>
      <Fieldset title="Promulgado">
        <LayerControl
          color="#339998"
          title="Redução"
          id="efetivado_reducao"
          onChange={ onChange }
        />
        <LayerControl
          color="#990598"
          title="Recategorização"
          id="efetivado_recategorizacao"
          onChange={ onChange }
        />
        <LayerControl
          color="#f78001"
          title="Extinção"
          id="efetivado_extincao"
          onChange={ onChange }
        />
      </Fieldset>
      <div className="filters-action-wrapper">
        <Button
          variant="contained"
          color="secondary"
          onClick={ onChange.bind(this, { action: 'openStatistics' }) }
        >
          Exibir estatísticas
        </Button>
      </div>
    </Accordion>
    <Accordion sessionTitle="Camadas" classes={ accordionStyle }>
      <LayerControl
        initialActive={ false }
        color="#008000"
        title="Unidades de Conservação"
        id="areas_protegidas"
        onChange={ onChange }
      />
      <LayerControl
        initialActive={ false }
        color="#132597"
        title="Biomas"
        id="biomas"
        onChange={ onChange }
      />
      <LayerControl
        initialActive={ false }
        color="#DFDB05"
        title="Estado"
        id="estados"
        onChange={ onChange }
      />
    </Accordion>
    {/* <Accordion sessionTitle="Vulnerabilidades" classes={ accordionStyle }>
      <LayerControl
        color="red"
        title="Grau de Ameaça"
        id="UC_Jan_2012_Grau_de_Ameaca"
        onChange={ onChange }
      />
      <LayerControl
        color="#89895d"
        title="Urbana"
        id="UC_Jan_2012_Urbana"
        onChange={ onChange }
      />        
      <LayerControl
        color="#4c00e6"
        title="Rodovias Planejadas"
        id="UC_Jan_2012_Rodovias_Planejadas_UC"
        onChange={ onChange }
      />
      <LayerControl
        color="#0073e6"
        title="Rodovias Instaladas"
        id="UC_Jan_2012_Rodovias_Instaladas_UC"
        onChange={ onChange }
      />
      <LayerControl
        color="#e6e600"
        title="Mineração"
        id="UC_Jan_2012_Mineracao"
        onChange={ onChange }
      />
      <LayerControl
        color="#e600e6"
        title="Linha Transmissão Planejada"
        id="UC_Jan_2012_Linha_Transmissao_Planejada"
        onChange={ onChange }
      />
      <LayerControl
        color="#ffb3b3"
        title="Linha Transmissão Instalada"
        id="UC_Jan_2012_Linha_Transmissao_Instalada"
        onChange={ onChange }
      />
      <LayerControl
        color="#e6b800"
        title="Geração Energia Planejado"
        id="UC_Jan_2012_Geracao_Energia_Planejado"
        onChange={ onChange }
      />
      <LayerControl
        color="#e68a00"
        title="Geração Energia Instalada"
        id="UC_Jan_2012_Geracao_Energia_Instalada"
        onChange={ onChange }
      />
      <LayerControl
        color="#97974e"
        title="Desmatamento"
        id="UC_Jan_2012_Desmatamento"
        onChange={ onChange }
      />
      <LayerControl
        color="#ac7339"
        title="Assentamentos"
        id="UC_Jan_2012_Assentamentos"
        onChange={ onChange }
      />
      <LayerControl
        color="#73e600"
        title="Agropecuária"
        id="UC_Jan_2012_Agropecuaria"
        onChange={ onChange }
      />
    </Accordion> */}
    <Accordion sessionTitle="Basemap" classes={ accordionStyle }>
      <BasemapSelect
        onChange={ onChange }
      />
    </Accordion>
  </>
)

MapFilterForm.propTypes = {
  // classes: PropTypes.object.isRequired,
}

export default MapFilterForm
