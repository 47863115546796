import React from 'react'
import Opacity from '@material-ui/icons/Opacity'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import ZoomOutMap from '@material-ui/icons/ZoomOutMap'
import Tooltip from '@material-ui/core/Tooltip'
import RcSlider from 'rc-slider'
import 'rc-slider/assets/index.css'

import './index.css'
import CustomSwitch from '../../../shared/components/CustomSwitch'

class LayerControl extends React.Component {
  state = {
    active: this.props.initialActive !== undefined ? this.props.initialActive : true,
    showOpacity: false,
    opacity: 1,
  }

  onSwitchChange = (event) => {
    this.setState({ active: event.target.checked }, () => {
      this.emmitter('switchChange')
    })
  }

  emmitter = (actionName) => this.props.onChange({ action: actionName, active: this.state.active, id: this.props.id, opacity: this.state.opacity })

  toggleOpacityBar = () => this.setState({ showOpacity: !this.state.showOpacity })

  render() {
    const { title = '', color = '#73e600' } = this.props

    return(
      <>
        <div className="layer-control-container">
          <div className="layer-control-switch">
            <CustomSwitch
              color={ color }
              checked={ this.state.active }
              value={ this.state.active }
              onChange={ this.onSwitchChange }
            /> <span>{ title }</span>
          </div>
          <div className="layer-control-other-actions">
            { /* <Tooltip title="Informações">
              <button onClick={ () => this.emmitter('showInfo') }>
                <ErrorOutline fontSize="small" color="disabled" />
              </button>
              </Tooltip> */ }
            <Tooltip title="Opacidade">
              <button onClick={ this.toggleOpacityBar }>
                <Opacity color="disabled" />
              </button>
            </Tooltip>
            {/* <Tooltip title="Centralizar mapa">
              <button onClick={ () => this.emmitter('centerMap') }>
                <ZoomOutMap fontSize="small" color="disabled" />
              </button>
            </Tooltip> */}
          </div>
        </div>

        { this.state.showOpacity &&
        <div className="opacity-bar">
          <div className="opacity-slider">
            <RcSlider
              min={ 0 }
              max={ 1 }
              step={ 0.05 }
              value={ this.state.opacity }
              onChange={ (value) => {
                this.setState({ opacity: value })
                this.emmitter('opacityChange')
              } }
            />
            <span>{ parseInt(this.state.opacity * 100) } %</span>
          </div>
        </div> }
      </>
    )
  }
}

export default LayerControl
