import React, { useEffect, useState } from 'react'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import './PointInfoBox.css'

const propsList = [
  { key: 'allnames', label: 'Nome da UC' },
  { key: 'EventType', label: 'Categoria de PADDD' },
  { key: 'EnactedPro', label: 'Status' },
  { key: 'YearPAGaze', label: 'Ano de criação' },
  { key: 'YearPADDD', label: 'Ano PADDD' },
  { key: 'Cause', label: 'Causa' },
  { key: 'Note', label: 'Informações adicionais' },
  { key: 'Biome', label: 'Bioma' },
  { key: 'State', label: 'Estado' },
];

const PointInfoBox = ({
  isOpen,
  loading,
  features,
  onChange
}) => {
  if (!isOpen) { return null; }

  const renderFeatures = () => {
    return _.map(features, (feature) => {
      return (
        <div className="feature-item" key={ `feature-${ feature.id }` }>
          <ul className="info-list">
            { _.map(propsList, (prop) => {
              return (
                <li key={ `feature-${ feature.id }-${ prop.key }` }>
                  <span>{ prop.label }</span>
                  <b>{ _.get(feature, `properties.${ prop.key }`) }</b>
                </li>
              );
            }) }
          </ul>
          <div className="feature-item-action">
            <Button
              color="secondary"
              variant="contained"
              onClick={ () => onChange({ action: 'changeFeatureIdIssue', data: feature.id }) }
            >
              Reportar
            </Button>
          </div>
        </div>
      );
    });
  };

  return (
    <Paper className="point-info-box" elevation={ 1 }>
      <h1>Informações do ponto</h1>
      <IconButton className="close-button" onClick={ () => onChange({ action: 'changePointData', data: null }) }>
        <CloseIcon />
      </IconButton>
      { loading &&
        <span>Carregando...</span>
      }
      { !loading && !_.isEmpty(features) &&
        <div className="features-wrapper">
          { renderFeatures() }
        </div>
      }
    </Paper>
  )
}

export default PointInfoBox
