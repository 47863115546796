import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { TextField, FormControl, Button, Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

const IssueForm = ({ formSubmit }) => (
  <Formik
    initialValues={{ title: '', description: '' }}
    mapPropsToValues={ props => props.values }
    validationSchema={ Yup.object().shape({
      title: Yup.string().required('O campo de título é requerido'),
      email: Yup.string().email('O campo de email não é válido'),
      description: Yup.string().required('O campo descrição é requerido')
    }) }
    onSubmit={ formSubmit }
    render={ ({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
      <Grid container item xs={ 12 }>
        <FormControl
          fullWidth={ true }
          component="form"
          onSubmit={ handleSubmit }
        >
          <TextField
            style={{ margin: 0, padding: 0 }}
            variant="filled"
            fullWidth={ true }
            name='title'
            type='text'
            label='Título'
            value={ values.title }
            onChange={ handleChange }
          />
          { errors.title && <Grid>{ errors.title }</Grid> }

          <TextField
            style={{ margin: 0, padding: 0 }}
            variant="filled"
            fullWidth={ true }
            name='email'
            type='email'
            label='Email'
            value={ values.email }
            onChange={ handleChange }
          />
          { errors.email && <Grid>{ errors.email }</Grid> }

          <TextField
            style={{ margin: 0, padding: 0 }}
            variant="filled"
            fullWidth={ true }
            name='description'
            type='text'
            label='Descrição'
            multiline
            value={ values.description }
            onChange={ handleChange }
          />
          { errors.description && <Grid>{ errors.description }</Grid> }

          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginTop: 10 }}
          >
            { isSubmitting ?
              (<CircularProgress color="inherit" />) :
              (<Button
                type='submit'
                variant="contained"
                color="primary"
              >Registrar evento PADDD</Button>)
            }
          </Grid>
        </FormControl>
      </Grid>) }
  />
)

export default IssueForm
