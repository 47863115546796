import React from 'react'
import { MuiThemeProvider } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Drawer from '@material-ui/core/Drawer'
import { Redirect, Link } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import mainTheme from '../../themes/main'
import AppNavbar from '../../../components/Dashboard/AppNavbar/AppNavibar'
import DashboardFooter from '../../../shared/components/DashboardFooter/DashboardFooter'
import { removeToken } from '../../../shared/utils/jwt'

const menuItems = [
  { name: 'Importar planilha', link: '/dashboard/import-spreadsheet' },
  { name: 'Eventos Paddd', link: '/dashboard/paddd-events' },
]

const SideMenu = ({ menuList = [], onChange = () => {} }) => {
  const buildItems = (item, key) => (
    <Link key={ key } onClick={ () => onChange({ action: 'clickMenu' }) } to={ item.link } style={{ textDecoration: 'none' }}>
      <ListItem button>
        <ListItemText primary={ item.name } />
      </ListItem>
    </Link>
  )

  return (
    <List>
      { menuList.map((i, k) => buildItems(i, k)) }
    </List>
  )
}

class DashboardLayout extends React.Component {
  state = {
    drawer: false,
    authenticated: true
  }

  toggleDrawer = () => this.setState({ drawer: !this.state.drawer })

  onLogoutClick = () => {
    const confirmation = confirm('Você tem certeza que deseja sair?')
    if (confirmation) {
      removeToken()
      this.setState({ authenticated: false })
    }
  }

  render() {
    const { children } = this.props

    if (!this.state.authenticated) return (<Redirect to="/" />)

    return(
      <MuiThemeProvider theme={ mainTheme }>
        <Grid container style={{ marginBottom: 65 }}>
          <AppNavbar
            onMenuClick={ this.toggleDrawer }
            onLogoutClick={ this.onLogoutClick }
          />

          <Drawer open={ this.state.drawer } onClose={ this.toggleDrawer }>
            <SideMenu
              menuList={ menuItems }
              onChange={ (data) => {
                if (data.action === 'clickMenu') {
                  this.toggleDrawer()
                }
              } }
            />
          </Drawer>

          { children }

          <DashboardFooter />
        </Grid>
      </MuiThemeProvider>
    )
  }
}
export default DashboardLayout
