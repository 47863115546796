import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Home from '../../pages/Home'
import Login from '../../pages/Login'
import DashboardRoutes from './Dashboard'

const routes = [
  {
    path: '/',
    component: (props) => (<Home { ...props } />),
  },
  {
    path: '/dashboard*',
    component: (props) => <DashboardRoutes { ...props } />,
  },
  {
    path: '/login',
    component: (props) => (<Login { ...props } />),
  },
]

const renderRoutes = (routes) => (
  routes.map((route) => (
    <Route exact key={ route.path } path={ route.path } render={ route.component } />
  ))
)

const Routes = () => {
  return(
    <Router>
      <>
        { renderRoutes(routes) }
      </>
    </Router>
  )
}

export default Routes
