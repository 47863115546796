import React from 'react'
import { Grid, Card, CardContent, Typography } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { isNil, isObjectLike } from 'lodash'

import './Login.css'
import LoginForm from '../components/Login/LoginForm/LoginForm'
import { CREATE_TOKEN } from '../shared/graphQl/Login/mutations'
import { setToken, decodeToken } from '../shared/utils/jwt'

class Login extends React.Component {
  state = {
    logged: false
  }

  componentDidMount = () => {
    const token = decodeToken()
    if (isObjectLike(token) && isNil(token.error)) this.setState({ logged: true })
  }

  render() {
    if (this.state.logged) return (<Redirect to="/dashboard" />)

    return(
      <Grid
        container
        alignItems="center"
        justify="center"
      >
        <Card className="login-card">
          <CardContent>
            <Grid container direction="column">
              <Typography variant="h4" component="h2">Paddd Track BR</Typography>
              <Typography variant="h5" component="h3">Faça o login</Typography>

              <Mutation
                mutation={ CREATE_TOKEN }
              >
                { ( createToken ) => (
                  <LoginForm
                    formSubmit={ (values, { setSubmitting }) => {
                      setSubmitting(true)
                      const { email, password } = values
                      const resp = createToken({ variables: { email, password } })
                      resp.then((response) => {
                        setSubmitting(false)
                        setToken(response.data.createToken)

                        this.setState({ logged: true })
                      }).catch(() => {
                        setSubmitting(false)
                      })
                    } }
                  />
                ) }
              </Mutation>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )
  }
}

export default Login
