const mapLayers = [
  {
    id: 'proposto_reducao',
    show: true,
    opacity: 1,
    baseZIndex: 200,
    props: {
      url: 'https://geoserver.ecostage.com.br/geoserver/wwf/ows',
      layers: 'wwf:padddtracker-layer',
      cql_filter: `EnactedPro = 'Proposto' and EventType = 'Redução'`
    }
  },
  {
    id: 'proposto_recategorizacao',
    show: true,
    opacity: 1,
    baseZIndex: 200,
    props: {
      url: 'https://geoserver.ecostage.com.br/geoserver/wwf/ows',
      layers: 'wwf:padddtracker-layer',
      cql_filter: `EnactedPro = 'Proposto' and EventType = 'Recategorização'`
    }
  },
  {
    id: 'proposto_extincao',
    show: true,
    opacity: 1,
    baseZIndex: 200,
    props: {
      url: 'https://geoserver.ecostage.com.br/geoserver/wwf/ows',
      layers: 'wwf:padddtracker-layer',
      cql_filter: `EnactedPro = 'Proposto' and EventType = 'Extinção'`
    }
  },
  {
    id: 'efetivado_reducao',
    show: true,
    opacity: 1,
    baseZIndex: 200,
    props: {
      url: 'https://geoserver.ecostage.com.br/geoserver/wwf/ows',
      layers: 'wwf:padddtracker-layer',
      cql_filter: `EnactedPro = 'Efetivado' and EventType = 'Redução'`
    }
  },
  {
    id: 'efetivado_recategorizacao',
    show: true,
    opacity: 1,
    baseZIndex: 200,
    props: {
      url: 'https://geoserver.ecostage.com.br/geoserver/wwf/ows',
      layers: 'wwf:padddtracker-layer',
      cql_filter: `EnactedPro = 'Efetivado' and EventType = 'Recategorização'`
    }
  },
  {
    id: 'efetivado_extincao',
    show: true,
    opacity: 1,
    baseZIndex: 200,
    props: {
      url: 'https://geoserver.ecostage.com.br/geoserver/wwf/ows',
      layers: 'wwf:padddtracker-layer',
      cql_filter: `EnactedPro = 'Efetivado' and EventType = 'Extinção'`
    }
  },
  {
    id: 'areas_protegidas',
    show: false,
    opacity: 1,
    baseZIndex: 100,
    props: {
      url: 'https://geoserver.ecostage.com.br/geoserver/wwf/ows',
      layers: 'wwf:areas_protegidas',
    },
  },
  {
    id: 'biomas',
    show: false,
    opacity: 1,
    baseZIndex: 100,
    props: {
      url: 'https://geoserver.ecostage.com.br/geoserver/wwf/ows',
      layers: 'wwf:biomas',
    },
  },
  {
    id: 'estados',
    show: false,
    opacity: 1,
    baseZIndex: 100,
    props: {
      url: 'https://geoserver.ecostage.com.br/geoserver/wwf/ows',
      layers: 'wwf:estados',
    },
  }
]

export default mapLayers
