import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'

import { getToken, removeToken } from '../../shared/utils/jwt'

const cache = new InMemoryCache()

const logErrors = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === 'Você não tem permissão para realizar esta ação') removeToken()
      alert(message)

      console.log(
        '[GraphQL error]: Message: ',
        message,
        ', Location: ',
        locations,
        ', Path: ',
        path
      )
    })
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const uploadLink = new createUploadLink({
  uri: 'http://localhost:3400/api/graphql'
  // uri: '/api/graphql'
})

const withToken = setContext((_, previousContext) => {
  let token = getToken()

  return {
    ...previousContext,
    headers: {
      ...(previousContext && previousContext.headers),
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    withToken,
    logErrors,
    uploadLink
  ])
})

export default client
