import React from 'react'
import { Grid, MuiThemeProvider, Typography } from '@material-ui/core'

import './DashboardTitle.css'
import mainTheme from '../../../app/themes/main'
import { mainColor } from '../../../app/themes/main/variables'

const DashboardTitle = ({ title = 'Dashboard' }) => (
  <MuiThemeProvider
    theme={ mainTheme }
  >
    <Grid
      container
      item
      xs={ 12 }
      className="dashboard-title-container"
      style={{ backgroundColor: mainColor }}
    >
      <Typography variant="h1">{ title }</Typography>
    </Grid>
  </MuiThemeProvider>
)

export default DashboardTitle
