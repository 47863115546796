import React, { Component } from 'react'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

import './LeafletMap.css'

const selectMap = (mapName) => {
  const maps = {
    dark: 'http://a.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
    topography: 'https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}{r}.png',
    default: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
    // default: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    satellite: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
  }

  return maps[mapName] || maps.default
}

class LeafletMap extends Component {
  map = null
  baseMap = null
  featureInfoUrl = ''
  layers = {}

  tiledLayer = (props) => L.TileLayer.WMS.extend({
    onAdd: function(map) {
      // Triggered when the layer is added to a map.
      //   Register a click listener, then do all the upstream WMS things
      L.TileLayer.WMS.prototype.onAdd.call(this, map)
      map.on('click', this.getFeatureInfo, this)
    },
    onRemove: function(map) {
      // Triggered when the layer is removed from a map.
      //   Unregister a click listener, then do all the upstream WMS things
      L.TileLayer.WMS.prototype.onRemove.call(this, map)
      map.off('click', this.getFeatureInfo, this)
    },
    getFeatureInfo: function(evt) {
      // const showResults = L.Util.bind(this.showGetFeatureInfo, this)
      props.onChange({ action: 'loading', data: true })
      const url = this.getFeatureInfoUrl(evt.latlng)
      fetch(url).then((response) => {
        return response.json()
      }).then((response) => {
        const layer = this.wmsParams.layers.replace('padddtracker:', '')
        props.onChange({
          action: 'showLayerInfoOnClick',
          data: { layer, features: response.features }
        })
      }).catch((response) => {
        console.log(response)
      })
    },
    getFeatureInfoUrl: function(latlng) {
      // Construct a GetFeatureInfo request URL given a point
      const point = this._map.latLngToContainerPoint(latlng, this._map.getZoom())
      const size = this._map.getSize()
      const params = {
        request: 'GetFeatureInfo',
        service: 'WMS',
        srs: 'EPSG:4326',
        styles: this.wmsParams.styles,
        transparent: this.wmsParams.transparent,
        version: this.wmsParams.version,
        format: this.wmsParams.format,
        bbox: this._map.getBounds().toBBoxString(),
        height: size.y,
        width: size.x,
        layers: this.wmsParams.layers,
        query_layers: this.wmsParams.layers,
        info_format: 'application/json'
      }
      params[params.version === '1.3.0' ? 'i' : 'x'] = parseInt(point.x)
      params[params.version === '1.3.0' ? 'j' : 'y'] = parseInt(point.y)
  
      return this._url + L.Util.getParamString(params, this._url, true)
    },
    showGetFeatureInfo: function(err, latlng, content) {
      if (err) return // do nothing if there's an error
      
      // Otherwise show the content in a popup, or something.
      L.popup({ maxWidth: 800})
        .setLatLng(latlng)
        .setContent(content)
        .openOn(this._map)
    }
  })

  buildMap = () => {
    const {
      selectedMap = 'default',
      layers = [],
      center = [-9.99766, -52.90838],
      zoom = 5,
      onChange = () => {}
    } = this.props
    const tiledWmsLayer = this.tiledLayer(this.props)
    L.tileLayer.betterWms = (url, options) => new tiledWmsLayer(url, options)

    this.map = L.map('map', {
      center,
      zoom
    })
    this.basemap = L.tileLayer.wms(selectMap(selectedMap)).addTo(this.map)
    layers.map((layer) => {
      const { url, otherProps } = layer
      this.layers[otherProps.className] = L.tileLayer.betterWms(url, { ...otherProps, identify: true })
      this.layers[otherProps.className].addTo(this.map)
    })
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.selectedMap !== nextProps.selectedMap) this.basemap.setUrl(selectMap(nextProps.selectedMap))
    if (this.props.center !== nextProps.center) this.map.flyTo(nextProps.center, 5)

    return true
  }

  componentDidMount() {
    this.buildMap()
  }

  render() {
    console.log('layers on map', this.layers);
    return(<div id="map" style={{ height: '100%' }}></div>)
  }

}

export default LeafletMap
