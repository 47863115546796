import React, { Component } from 'react'
import { MuiThemeProvider } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import Header from '../../../components/Home/Header/Header'
import mainTheme from '../../themes/main'
import PopupIndex from '../../../components/Home/PopupIndex/PopupIndex'
// import MoreInformation from '../../../components/Home/MoreInformation/MoreInformation'

class MainLayout extends Component {
  state = {
    value: 0,
  }

  render() {
    const { children } = this.props

    return (
      <MuiThemeProvider theme={ mainTheme }>
        <Grid container>
          <Grid item xs={ 12 }>
            <Header />
          </Grid>

          { children }

          { /* <MoreInformation /> */ }

          <PopupIndex />
        </Grid>
      </MuiThemeProvider>
    )
  }
}

export default MainLayout
