import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import logo from '../../../assets/img/CI+WWF-logo.png'
import Menu from '../Menu/Menu'

const styles = {
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  grow: {
    flexGrow: 1,
  },
}

const Header = ({
  items = [
    { link: 'https://padddbrasil.org.br', name: 'Ir para PADDD Tracker Brasil' },
  ],
  classes = {}
}) => {

  return (
    <AppBar className={ classes.root }>
      <Toolbar>
        <Grid className={ classes.grow }>
          <Link to={ '/' }>
            <img src={ logo } alt={ 'Logo' } style={{ maxHeight: 45 }} />
          </Link>
        </Grid>

        <Menu
          items={ items }
        />
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(styles)(Header)
