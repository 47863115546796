import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { TextField, FormControl, Button, Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'


const LoginForm = ({ formSubmit }) => (
  <Formik
    initialValues={{ email: '', password: '' }}
    mapPropsToValues={ props => props.values }
    validationSchema={ Yup.object().shape({
      email: Yup.string().email('Favor digite um email válido').required('O campo de email é requerido'),
      password: Yup.string().required('O campo de senha é requerido')
    }) }
    onSubmit={ formSubmit }
    render={ ({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
      <FormControl
        fullWidth={ true }
        component="form"
        onSubmit={ handleSubmit }
      >
        <TextField
          name='email'
          type='email'
          label='E-mail'
          value={ values.email }
          onChange={ handleChange }
        />
        { errors.email && <p>{ errors.email }</p> }

        <TextField
          name='password'
          type='password'
          label='Senha'
          value={ values.password }
          onChange={ handleChange }
        />
        { errors.password && <p>{ errors.password }</p> }

        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginTop: 10 }}
        >
          { isSubmitting ?
            (<CircularProgress />) :
            (<Button
              type='submit'
              variant="contained"
              color="primary"
            >Enviar</Button>)
          }
        </Grid>
      </FormControl>) }
  />
)

export default LoginForm
