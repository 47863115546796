import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import './index.css'
import imgSatellite from '../../../assets/img/basemap-satellite.jpg'
import imgDark from '../../../assets/img/basemap-dark.jpg'
import imgDefault from '../../../assets/img/basemap-default.jpg'
import imgTopography from '../../../assets/img/basemap-topography.jpg'

const BasemapSelect = ({ onChange = () => {} }) => {
  return(
    <Grid container>
      <Grid item xs={ 3 }>
        <Button
          className="basemap-button"
          color="secondary"
          onClick={ () => onChange({ action: 'selectBasemap', data: 'satellite' }) }
        >
          <div className="basemap-inner">
            <img src={ imgSatellite } />
            <span>Satelite</span>
          </div>
        </Button>
      </Grid>

      <Grid item xs={ 3 }>
        <Button
          className="basemap-button"
          color="secondary"
          onClick={ () => onChange({ action: 'selectBasemap', data: 'topography' }) }
        >
          <div className="basemap-inner">
            <img src={ imgTopography } />
            <span>Topográfico</span>
          </div>
        </Button>
      </Grid>

      <Grid item xs={ 3 }>
        <Button
          className="basemap-button"
          color="secondary"
          onClick={ () => onChange({ action: 'selectBasemap', data: 'dark' }) }
        >
          <div className="basemap-inner">
            <img src={ imgDark } />
            <span>Escuro</span>
          </div>
        </Button>
      </Grid>

      <Grid item xs={ 3 }>
        <Button
          className="basemap-button"
          color="secondary"
          onClick={ () => onChange({ action: 'selectBasemap', data: 'default' }) }
        >
          <div className="basemap-inner">
            <img src={ imgDefault } />
            <span>Padrão</span>
          </div>
        </Button>
      </Grid>
    </Grid>
  )
}

export default BasemapSelect
