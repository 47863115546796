import React, { useState, createRef } from 'react'
import { FormControl } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { isEmpty, get } from 'lodash'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import Chip from '@material-ui/core/Chip'
import Send from '@material-ui/icons/Send'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import CircularProgress from '@material-ui/core/CircularProgress'

const FormImport = ({ formSubmit, isSubmited = false }) => {
  const openFileDialog = () => fileInputRef.current.click()

  const emptyRef = createRef()
  const [fileInputRef, setFileInputRef] = useState(emptyRef)
  
  return (
    <Formik
      initialValues={{ spreadsheet: '' }}
      mapPropsToValues={ props => props.values }
      validationSchema={ Yup.object().shape({
        spreadsheet: Yup.mixed().required('Favor selecione um arquivo .XLSX e tente novamente')
      }) }
      onSubmit={ (values) => {
        const params = { ...values, spreadsheet: fileInputRef.current.files }
        isSubmited = false
        formSubmit(params)
      } }
      render={ ({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <FormControl
          component="form"
          onSubmit={ handleSubmit }
        >
          { !isEmpty(get(fileInputRef, 'current.files')) &&
            <Chip
              style={{ marginBottom: 20 }}
              icon={ <FileCopyOutlined /> }
              label={ get(fileInputRef, 'current.files')[0].name }
              variant="outlined"
            /> }

          <Button
            variant="outlined"
            label="Enviar planilha"
            onClick={ openFileDialog }
          >
            Incluir planilha
            <CloudUploadIcon style={{ marginLeft: 10 }} />

            <input
              name="spreadsheet"
              type="file" 
              ref={ fileInputRef }
              style={{ display: 'none' }}
              value={ values.spreadsheet }
              onChange={ (e) => {
                setFileInputRef(emptyRef)
                if (!isEmpty(get(fileInputRef, 'current.files'))) {
                  setFileInputRef(fileInputRef)
                }

                handleChange(e)
              } }
            />
          </Button>

          { errors.file && <span>{ errors.file }</span> }

          { !isEmpty(get(fileInputRef, 'current.files')) &&
            (<Button
              variant="contained"
              color="primary"
              type="submit"
            >
              <Send style={{ marginRight: 10 }} />
              Importar planilha
            </Button>) }

          { (isSubmitting && !isSubmited) &&
            <Grid container justify="center"><CircularProgress color="inherit" /></Grid> }
        </FormControl>
      ) }
    />
  )
}

export default FormImport
