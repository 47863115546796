import gql from 'graphql-tag'

const ALL_SPREADSHEETS = gql`
query($page: Int!, $numberOfItems: Int!) {
  allUcs(page: $page, numberOfItems: $numberOfItems) {
    allName
    anoCria6
    areaAffected
    atoLega9
    categori3
    causes
    codigoU11
    dtUltim10
    enactedPro
    esfera5
    eventType
    geom
    gid
    gid7
    grupo4
    idUc0
    idWcmc2
    legalType
    nomeOrg12
    nomeUc1
    notes
    offset
    padddtrackerId
    primaryNam
    qualidad8
    reversal
    sizePost
    sizePre
    source
    supporting
    wdpaid
    yearPaGaze
    yearPaddd
    yrReverse
  }
  countUcs
}
`

export {
  ALL_SPREADSHEETS
}
