import React from 'react'
import { Grid, Card } from '@material-ui/core'
import { Mutation } from 'react-apollo'

import DashboardTitle from '../../shared/components/DashboardTitle/DashboardTitle'
import FormImport from '../../components/Dashboard/ImportSpreadsheet/FormImport'
import DataTable from '../../shared/components/DataTable/DataTable'
import Text from '../../shared/components/Text/Text'
import { UPLOAD_FILE } from '../../shared/graphQl/Dashboard/mutations'

class ImportSpreadsheet extends React.Component {
  state = {
    isSubmited: false,
  }

  render() {
    return(
      <Grid container>
        <DashboardTitle title="Importar dados da planilha" />
        <Grid
          container
          justify="center"
        >
          <Grid
            item
            xs={ 3 }
          >
            <Text>Enviar arquivo</Text>
            <Card>
              <Grid
                container
                justify="center"
              >
                <Mutation
                  mutation={ UPLOAD_FILE }
                >
                  { (uploadFile) => (
                    <FormImport
                      isSubmited={ this.state.isSubmited }
                      formSubmit={ (r) => {
                        const file = r.spreadsheet[0]
                        uploadFile({ variables: { file, ignoreValidation: true } })
                          .then((response) => {
                            let message = ''
                            response.data.uploadFile.messages.forEach((msg) => message += ` ${msg}`)
                            alert(message)

                            this.setState({ isSubmited: !this.state.isSubmited })
                          }).catch((err) => {
                            console.log(err)
                            this.setState({ isSubmited: !this.state.isSubmited })
                          })
                      } }
                    />
                  ) }
                </Mutation>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Grid container item xs={ 12 }>
          <DataTable />
        </Grid>
      </Grid>
    )
  }

}

export default ImportSpreadsheet
