import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'

import './DraggableMapLayers.css'
import Accordion from '../../../shared/components/Accordion'

const Retangle = ({ backgroundColor = '#990099', opacity = 1 }) =>
  (<div style={{ backgroundColor, opacity, width: 15, height: 9, marginRight: 4, display: 'inline-block' }}></div>)

const Circle = ({ backgroundColor = '#990099', opacity = 1 }) =>
  (<div style={{ backgroundColor, opacity, width: 15, height: 15, marginRight: 4, borderRadius: '50%', display: 'inline-block' }}></div>)

class DraggableMapLayers extends Component {
  state = {
    items: [
      { key: '1', sessionTitle: 'Vulnerabilidades' },
      { key: '2', sessionTitle: 'Áreas Protegidas' },
    ],
    opened: true
  }

  constructor(props) {
    super(props)
    this.onDragEnd = this.onDragEnd.bind(this)
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = this.reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    )

    this.setState({
      items
    }, () => {
      this.props.onChange({ action: 'layersReorder', items })
    })
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
  
    return result
  }

  getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#f0f0f0' : '#fff',
    ...draggableStyle
  })

  render() {
    return (
      <div style={{ position: 'absolute', width: 350, maxWidth: '100%', bottom: 0, right: 5, zIndex: 1001 }}>
        <Grid container item xs={ 12 }>
          <Card style={{ width: '100%' }}>
            <Accordion sessionTitle="LEGENDA" classes={{ title: { color: '#555' }, content: { padding: '0 0 1rem 1rem' } }} >
              <DragDropContext
                onDragEnd={ this.onDragEnd }
                style={{ position: 'relative' }}
              >
                <Droppable droppableId='droppable'>
                  { (provided) => (
                    <div
                      { ...provided.droppableProps }
                      ref={ provided.innerRef }
                    >
                      { this.state.items.map((v, i) => (
                        <Draggable key={ v.key } draggableId={ v.key } index={ i }>
                          { (dragProvided, snapshot) => (
                            <div
                              ref={ dragProvided.innerRef }
                              { ...dragProvided.draggableProps }
                              { ...dragProvided.dragHandleProps }
                              style={ {
                                ...this.getItemStyle(
                                  snapshot.isDragging,
                                  dragProvided.draggableProps.style
                                ),
                                width: '100%'
                              } }
                            >
                              <h1 className="draggable-item-title">{ v.sessionTitle }</h1>
                              { v.sessionTitle === 'Vulnerabilidades' &&
                                <Grid className="draggable-item" container>
                                  <Grid item xs={ 6 }>
                                    <ul className="draggable-item-list">
                                      <li>
                                        <Circle /> Urbana
                                      </li>
                                      <li>
                                        <Circle backgroundColor="#339999" /> Rodovias Planejadas
                                      </li>
                                      <li>
                                        <Circle backgroundColor="#fd8000" /> Rodovias Instaladas
                                      </li>
                                      <li>
                                        <Circle backgroundColor="#fd8000" /> Geração Energia Planejado
                                      </li>
                                      <li>
                                        <Circle backgroundColor="#fd8000" /> Geração Energia Instalada
                                      </li>
                                      <li>
                                        <Circle backgroundColor="#fd8000" /> Assentamentos
                                      </li>
                                    </ul>
                                  </Grid>
                                  <Grid item xs={ 6 }>
                                    <ul className="draggable-item-list">
                                      <li>
                                        <Circle opacity=".5" /> Mineração
                                      </li>
                                      <li>
                                        <Circle backgroundColor="#339999" opacity=".5" /> Linha Transmissão Planejada
                                      </li>
                                      <li>
                                        <Circle backgroundColor="#fd8000" opacity=".5" /> Linha Transmissão Instalada
                                      </li>
                                      <li>
                                        <Circle backgroundColor="#fd8000" opacity=".5" /> Grau de Ameaça
                                      </li>
                                      <li>
                                        <Circle backgroundColor="#fd8000" opacity=".5" /> Desmatamento
                                      </li>
                                      <li>
                                        <Circle backgroundColor="#fd8000" opacity=".5" /> Agropecuária
                                      </li>
                                    </ul>
                                  </Grid>
                                </Grid> }

                              { v.sessionTitle === 'Áreas Protegidas' &&
                                <Grid className="draggable-item" container>
                                  <Grid item xs={ 6 }>
                                    <ul className="draggable-item-list">
                                      <li><Retangle backgroundColor="#229A00" /> AREAS PROTEGIDAS</li>
                                    </ul>
                                  </Grid>
                                </Grid> }
                            </div>
                          ) }
                        </Draggable>
                      )) }
                    </div>
                  ) }
                </Droppable>
              </DragDropContext>
            </Accordion>
          </Card>
        </Grid>
      </div>
    )
  }
}

export default DraggableMapLayers
