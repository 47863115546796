import React from 'react'
import { Grid } from '@material-ui/core'

import DashboardTitle from '../../shared/components/DashboardTitle/DashboardTitle'
import DataTable from '../../shared/components/DataTableEventsPaddd'

class PadddEvents extends React.Component {
  state = {
    isSubmited: false,
  }

  render() {
    return(
      <Grid container>
        <DashboardTitle title="Eventos Paddd" />
        { /* <Grid
          container
          justify="center"
        >
          <Grid
            item
            xs={ 3 }
          >
            <Text>Enviar arquivo</Text>
            <Card>
              <Grid
                container
                justify="center"
              >
                <Mutation
                  mutation={ UPLOAD_FILE }
                >
                  { (uploadFile) => (
                    <FormImport
                      isSubmited={ this.state.isSubmited }
                      formSubmit={ (r) => {
                        const file = r.spreadsheet[0]
                        uploadFile({ variables: { file, ignoreValidation: true } })
                          .then((response) => {
                            let message = ''
                            response.data.uploadFile.messages.forEach((msg) => message += ` ${msg}`)
                            alert(message)

                            this.setState({ isSubmited: !this.state.isSubmited })
                          }).catch((err) => {
                            console.log(err)
                            this.setState({ isSubmited: !this.state.isSubmited })
                          })
                      } }
                    />
                  ) }
                </Mutation>
              </Grid>
            </Card>
          </Grid>
        </Grid> */ }

        <Grid container item xs={ 12 }>
          <DataTable />
        </Grid>
      </Grid>
    )
  }

}

export default PadddEvents
