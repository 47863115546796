import gql from 'graphql-tag'

export const ALL_ISSUES = gql`
query($page: Int!, $numberOfItems: Int!) {
  allIssues(page: $page, numberOfItems: $numberOfItems) {
    id
    title
    description
    shapeId
    createdAt
    updatedAt
  }
  countIssues
}
`
