import React, { useState } from 'react'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'

const CustomSwitch = ({
  color = '#73e600',
  onChange = () => {},
  value = false,
}) => {
  const [ state, setState ] = useState({ value })
  const handleChange = event => {
    setState({ value: event.target.checked })
    onChange(event)
  }
  const MySwitch = withStyles({
    switchBase: {
      color: `${ color } !important`,
      '&$checked': {
        color: `${ color } !important`,
      },
      '&$checked': {
        '& + $track': {
          backgroundColor: '#52d869',
        },
      },
    },
    bar: {
      color
    },
    icon: {
      color,
      backgroundColor: '#fff'
    },
    iconChecked: {
      backgroundColor: `${ color } !important`,
    },
    checked: {
      color
    },
  })(Switch)

  return(
    <>
      <MySwitch
        checked={ state.value }
        onChange={ handleChange }
      />
    </>
  )
}

export default CustomSwitch
