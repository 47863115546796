import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ExitToApp from '@material-ui/icons/ExitToApp'

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
}

const AppNavbar = ({ onMenuClick = () => {}, onLogoutClick = () => {}, classes = {} }) => (
  <AppBar position="static" className={ classes.root }>
    <Toolbar>
      <IconButton
        className={ classes.menuButton }
        color="inherit"
        aria-label="Menu"
        onClick={ onMenuClick }
      >
        <MenuIcon />
      </IconButton>
      <Typography variant="h3" color="inherit" className={ classes.grow }>
        PadddTracker BR
      </Typography>
      <Button
        variant="outlined"
        color="inherit"
        onClick={ onLogoutClick }
      >
        <ExitToApp />
        Sair
      </Button>
    </Toolbar>
  </AppBar>
)

export default withStyles(styles)(AppNavbar)
