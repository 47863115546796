import React from 'react'
import { Grid, MuiThemeProvider, Typography } from '@material-ui/core'

import './DashboardFooter.css'
import mainTheme from '../../../app/themes/main'
import { mainColor } from '../../../app/themes/main/variables'

const DashboardFooter = ({ title = 'Ecostage ® All rights reserved' }) => (
  <MuiThemeProvider
    theme={ mainTheme }
  >
    <Grid
      container
      item
      xs={ 12 }
      className="dashboard-footer-container"
      style={{ backgroundColor: mainColor }}
    >
      <Typography variant="h4">{ title }</Typography>
    </Grid>
  </MuiThemeProvider>
)

export default DashboardFooter
