import React from 'react'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Text from '../../../shared/components/Text/Text'
import './PopupIndex.css'

class PopupIndex extends React.Component {
  state = {
    open: true
  }

  close = () => this.setState({ open: false })

  checkShowPopup = () => (this.state.open && localStorage.getItem('hideInitialPopup') === null)

  checkHidePopupOnStartup = () => localStorage.getItem('hideInitialPopup') ? localStorage.removeItem('hideInitialPopup') : localStorage.setItem('hideInitialPopup', 'true')

  render() {
    return (
      <div
        className="initial-popup"
        style={{
          display: this.checkShowPopup() ? 'inherit' : 'none'
        }}
      >
        <Card>
          <CardContent>
            <Grid
              item
              container
              xs={ 12 }
              spacing={ 0 }
              justify="space-between"
              alignContent="center"
            >
              <Grid
                item
                xs={ 10 }
                spacing={ 0 }
                alignContent="center"
              >
                <h1>MONITORE OS PROCESSOS DE ALTERAÇÕES DE Áreas Protegidas</h1>
              </Grid>
              <Grid
                item
                xs={ 2 }
                spacing={ 0 }
                alignContent="flex-end"
              >
                <IconButton className="closeButton" onClick={ this.close }>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
  
            <Text>
              PADDDtrackerBrasil.org.br é uma plataforma para reportar e monitorar as alterações nos limites e categorias das Áreas Protegidas brasileiras.
            </Text>
            <Text>
              Saiba mais sobre PADDD
            </Text>
            <Text>
              Reporte um PADDD
            </Text>

            <FormControlLabel
              control={
                <Checkbox onChange={ this.checkHidePopupOnStartup } value="true" />
              }
              label="Não mostrar novamente"
            />
          </CardContent>
        </Card>
      </div>
    )
  }
}

export default PopupIndex
