import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { has } from 'lodash'

import { decodeToken } from '../../shared/utils/jwt'
import DashboardLayout from '../../app/layouts/DashboardLayout/index'
import ImportSpreadsheet from '../../pages/Dashboard/ImportSpreadsheet'
import PadddEvents from '../../pages/Dashboard/PadddEvents'

const checkIfAuthenticated = (component) => {
  const token = decodeToken()
  if (has(token, 'error')) return (<Redirect to="/login" />)

  return component
}

const routes = [
  {
    path: '/dashboard',
    component: (props) => <Redirect to="/dashboard/import-spreadsheet"  { ...props } />,
  },
  {
    path: '/dashboard/import-spreadsheet',
    component: (props) => checkIfAuthenticated(<ImportSpreadsheet { ...props } />),
  },
  {
    path: '/dashboard/paddd-events',
    component: (props) => checkIfAuthenticated(<PadddEvents { ...props } />),
  }
]

const renderRoutes = (routes) => (
  routes.map((route, key) => (
    <Route key={ route.path + key } path={ route.path } render={ route.component } />
  ))
)

const Routes = () => {
  return(
    <DashboardLayout>
      { renderRoutes(routes) }
    </DashboardLayout>
  )
}

export default Routes
