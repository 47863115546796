import React, { useEffect, useState } from 'react'
import ReactEcharts from 'echarts-for-react'
import _ from 'lodash'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import './StatisticsBox.css'

// const YEARS = [1959, 1961, 1975, 1980, 1981, 1986, 1987, 1990, 1991, 1992, 1995, 1996, 1997, 1998, 1999, 2003, 2005, 2006, 2007, 2008, 2010, 2018];

const MARKS = [
  { value: 1959, label: '1959-1969' },
  { value: 1970, label: '1970-1979' },
  { value: 1980, label: '1980-1989' },
  { value: 1990, label: '1990-1999' },
  { value: 2000, label: '2000-2009' },
  { value: 2010, label: '2010-2019' },
  { value: 2020, label: '2020-2029' },
];

function PieChart({
  title,
  data,
}) {
  return (
    <ReactEcharts
      showLoading={ _.isEmpty(data) }
      loadingOption={ { text: 'Carregando...', color: '#2f89cc' } }
      style={ { height: 320, width: '100%' } }
      option={ {
        title: {
          text: title,
          left: 'center',
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: 'Total',
            type: 'pie',
            radius: '75%',
            data: data,
            // label: {
            //   formatter: '{b}: {c}'
            // }
            emphasis: {
              disabled: true
            },
            label: {
              position: 'inner',
              fontSize: 14,
              fontWeight: 'bold',
              formatter: '{c}'
            },
            labelLine: {
              show: false
            },
          }
        ]
      } }
    />
  );
}

function BarChart({
  series
}) {
  return (
    <ReactEcharts
      showLoading={ _.isEmpty(series) }
      loadingOption={ { text: 'Carregando...', color: '#2f89cc' } }
      style={ { height: 320, width: '100%' } }
      option={ {
        title: {
          text: 'Número de eventos de PADDD por categoria',
          left: 'center',
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: _.map(MARKS, 'label')
        },
        yAxis: {
            type: 'value'
        },
        series
      } }
    />
  );
}

function fetchLayerData(layerId, activeTerritoryType, activeTerritory) {
  const geoserverBaseUrl = `https://geoserver.ecostage.com.br/geoserver/wwf/wfs?`;
  let wfsParams = [
    'service=WFS',
    'version=2.0.0',
    'request=GetFeature',
    `typeNames=${ layerId }`,
    'outputFormat=application/json',
    'srsName=EPSG:4326',
  ];

  if (activeTerritory) {
    wfsParams = wfsParams.concat(`cql_filter=${ activeTerritoryType } = '${ _.get(activeTerritory, 'id') }'`);
  }

  return fetch(`${ geoserverBaseUrl }${ wfsParams.join('&') }`)
    .then((resp) => resp.json());
}

const mapLayerIdToColor = {
  'Proposto-Redução': '#acd5d5',
  'Proposto-Recategorização': '#d596d5',
  'Proposto-Extinção': '#fabd7d',
  'Efetivado-Redução': '#339998',
  'Efetivado-Recategorização': '#990598',
  'Efetivado-Extinção': '#f78001'
};

const mapActiveTerritoryTypeToLabel = {
  'Biome': 'Bioma',
  'State': 'Estado',
};

const StatisticsBox = ({
  activeTerritoryType,
  activeTerritory,
  activeYear,
  layers,
  isOpen,
  onClose
}) => {
  const [parsedData, setParsedData] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fetchLayerData('wwf:padddtracker-layer', activeTerritoryType, activeTerritory)
        .then((data) => {
          const features = _.get(data, 'features');
          const filteredFeatures = _.filter(features, (f) => {
            const group = _.get(f, 'properties.EnactedPro');
            const type = _.get(f, 'properties.EventType');

            return group && type;
          });
          const groupedData = _.groupBy(filteredFeatures, (f) => {
            const group = _.get(f, 'properties.EnactedPro');
            const type = _.get(f, 'properties.EventType');

            return `${ group }-${ type }`;
          });
          const parsedSeries = _.map(groupedData, (data, key) => {
            return {
              name: key,
              data: _.map(MARKS, (item) => {
                const years = _.split(item.label, '-');
                const firstYear = Number(_.first(years));
                const lastYear = Number(_.last(years));
                const items = _.filter(data, (f) => {
                  const year = _.get(f, 'properties.YearPADDD');

                  return year >= firstYear && year <= lastYear;
                });
                return !_.isEmpty(items) ? _.size(items) : null;
              }),
              color: mapLayerIdToColor[key],
              type: 'bar'
            };
          });

          setParsedData(parsedSeries);
        });
    }
  }, [isOpen, activeTerritoryType, activeTerritory]);

  const filteredSeries = _.filter(parsedData, (item) => {
    const mapSeriesNameToLayerId = {
      'Proposto-Redução': 'proposto_reducao',
      'Proposto-Recategorização': 'proposto_recategorizacao',
      'Proposto-Extinção': 'proposto_extincao',
      'Efetivado-Redução': 'efetivado_reducao',
      'Efetivado-Recategorização': 'efetivado_recategorizacao',
      'Efetivado-Extinção': 'efetivado_extincao',
    };
    const { name } = item;
    const layerId = mapSeriesNameToLayerId[name];
    const layerData = _.find(layers, { id: layerId });

    return _.get(layerData, 'show');
  });

  const yearMarkIndex = _.findIndex(MARKS, { label: activeYear });
  console.log('yearMarkIndex', yearMarkIndex);
  const parsedPieChartSeries = _.map(filteredSeries, (serie) => {
    return {
      name: serie.name,
      value: _.sum(serie.data),
      // value: _.get(serie.data, `[${ yearMarkIndex }]`),
      itemStyle: {
        color: serie.color
      }
    };
  });
  console.log('parsedPieChartSeries', parsedPieChartSeries);

  if (!isOpen) { return null; }

  return (
    <Paper className="statistics-box" elevation={ 1 }>
      <h1>Estatísticas</h1>
      { activeTerritory &&
        <h2>{ mapActiveTerritoryTypeToLabel[activeTerritoryType] }: {  _.get(activeTerritory, 'id') }</h2>
      }
      <IconButton className="close-button" onClick={ () => onClose({ action: 'closeStatistics' }) }>
        <CloseIcon />
      </IconButton>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 3 }>
          <PieChart
            title="1959-2029"
            // title={ activeYear }
            data={ parsedPieChartSeries }
          />
        </Grid>
        <Grid item xs={ 9 }>
          <BarChart
            key={ `bar-chart-${ _.size(filteredSeries) }` }
            series={ filteredSeries }
          />
        </Grid>
      </Grid>
      <ul className="legendList">
        <li className="legendItem">
          <span className="legendItemColor" style={ { backgroundColor: '#acd5d5' } } />
          <span className="legendItemLabel">Proposto-Redução</span>
        </li>
        <li className="legendItem">
          <span className="legendItemColor" style={ { backgroundColor: '#d596d5' } } />
          <span className="legendItemLabel">Proposto-Recategorização</span>
        </li>
        <li className="legendItem">
          <span className="legendItemColor" style={ { backgroundColor: '#fabd7d' } } />
          <span className="legendItemLabel">Proposto-Extinção</span>
        </li>
        <li className="legendItem">
          <span className="legendItemColor" style={ { backgroundColor: '#339998' } } />
          <span className="legendItemLabel">Efetivado-Redução</span>
        </li>
        <li className="legendItem">
          <span className="legendItemColor" style={ { backgroundColor: '#990598' } } />
          <span className="legendItemLabel">Efetivado-Recategorização</span>
        </li>
        <li className="legendItem">
          <span className="legendItemColor" style={ { backgroundColor: '#f7800' } } />
          <span className="legendItemLabel">Efetivado-Extinção</span>
        </li>
      </ul>
    </Paper>
  )
}

export default StatisticsBox
