import React from 'react'
import Grid from '@material-ui/core/Grid'
import Close from '@material-ui/icons/Close'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { Mutation } from 'react-apollo'
import CardContent from '@material-ui/core/CardContent'

import { CREATE_ISSUE } from './mutations'
import IssueForm from '../IssueForm'
import './index.css'

const PopupIssue = ({ onChange = () => {}, shapeId = '' }) => (
  <Dialog
    open={ true }
    onClose={ () => onChange({ action: 'closeIssuePopup' }) }
  >
    <Card>
      <CardContent>
        <Grid container item xs={ 12 } justify="space-between">
          <h1 style={{ marginLeft: 15 }}>Reportar evento PADDD</h1>
          <Button onClick={ () => onChange({ action: 'closeIssuePopup' }) }>
            <Close /> 
          </Button>
        </Grid>
        <Mutation
          mutation={ CREATE_ISSUE }
        >
          { ( createIssue ) => (
          <IssueForm formSubmit={ (values, { setSubmitting }) => {
              setSubmitting(true)
              const { title = '', description = '', email = '' } = values
              const resp = createIssue({ variables: { title, description, email, shapeId } })
              resp.then((response) => {
                setSubmitting(false)
                alert('Evento PADDD registrado com sucesso!')
                onChange({ action: 'closeIssuePopup' })
              }).catch(() => {
                setSubmitting(false)
                alert('Falha ao registrar Evento PADDD, favor tente novamente mais tarde')
              })
            } }
          /> ) }
        </Mutation>
      </CardContent>
    </Card>
  </Dialog>
)

export default PopupIssue
