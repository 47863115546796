import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { Query } from 'react-apollo'

import { ALL_ISSUES } from './queries'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
})

class DataTableEventsPaddd extends Component {
  state = {
    items: [],
    page: 0,
    numberOfItems: 10,
  }

  handleChangePage = (event, page) => {
    this.setState({ page })
  }

  handleChangeNumberOfItems = (event) => {
    this.setState({ numberOfItems: parseInt(event.target.value, 10), page: 0 })
  }

  render() {
    const { classes } = this.props

    return (
      <Query
        query={ ALL_ISSUES }
        variables={{ page: this.state.page, numberOfItems: this.state.numberOfItems }}
      >
        { ({ loading, error, data, refetch }) => {
          if (loading) return(
            <Grid container item justify="center" alignContent="center">
              <CircularProgress color="inherit" />
            </Grid>
          )
          if (error) return(
            <Grid container item justify="center" alignContent="center">
              <Typography variant="h1">{ error }</Typography>
            </Grid>
          )

          return(
            <Paper className={ classes.root }>
              <Table className={ classes.table }>
                <TableHead>
                  <TableRow>
                    <TableCell>id</TableCell>
                    <TableCell>Título</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Shape Id</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { data.allIssues.map(row => (
                    <TableRow key={ row.id }>
                      <TableCell>{ row.id }</TableCell>
                      <TableCell>{ row.title }</TableCell>
                      <TableCell>{ row.description }</TableCell>
                      <TableCell>{ row.shapeId }</TableCell>
                    </TableRow>
                  )) }
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={ [ 10, 30, 50 ] }
                      colSpan={ 3 }
                      count={ data.countIssues }
                      rowsPerPage={ this.state.numberOfItems }
                      page={ this.state.page }
                      SelectProps={{
                        inputProps: { 'aria-label': 'Linhas por página' },
                        native: true,
                      }}
                      onChangePage={ (event, page) => {
                        this.handleChangePage(event, page)
                        refetch()
                      } }
                      onChangeRowsPerPage={ (event) => {
                        this.handleChangeNumberOfItems(event)
                        refetch()
                      } }
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Paper>
          )
        } }
      </Query>
    )
  }
}

DataTableEventsPaddd.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DataTableEventsPaddd)
