import { createMuiTheme } from '@material-ui/core'

import { mainColor, secondaryColor, fontColor, background, gray } from './variables'

// For some general info about themes on material-ui, check the following
// docs: https://material-ui.com/customization/themes/

export default createMuiTheme({
  // This will override default props per specific component.
  // The exact name to be used is shown in the documentation
  // of each component.
  //   i.e. https://material-ui.com/api/card/#css (MuiCard)
  props: {
    MuiAppBar: {
      elevation: 1,
      position: 'fixed',
    },
    MuiCard: {
      elevation: 1,
      raised: false
    },
    MuiPaper: {
      elevation: 0,
      square: true
    },
    MuiInput: {
      disableUnderline: true
    },
    MuiInputLabel: {
      shrink: true
    }
  },

  // This will override default styles per element of each specific component
  // The list of elements available for customization in each component is
  // documented in the API reference page of each component
  //   i.e. https://material-ui.com/api/list-item/#css (root, container,
  //     focusVisible, default, etc)
  overrides: {
    MuiCard: {
      root: {
        backgroundColor: '#fff',
        color: fontColor
      }
    },
    MuiFormControl: {
      root: {
        boxSizing: 'border-box',
        paddingLeft: '5px',
        paddingRight: '5px',
        margin: '10px'
      }
    },
    MuiFormLabel: {
      root: {
        color: fontColor
      }
    },
    MuiInput: {
      root: {
        borderColor: fontColor,
        borderRadius: '5px',
        borderStyle: 'solid',
        borderWidth: '1px',
        boxSizing: 'border-box',
        paddingLeft: '5px',
        paddingRight: '5px'
      },
      underline: {
        border: '0'
      },
      '&$disabled': {
        borderColor: 'rgba(0, 0, 0, 0.38)',
        backgroundColor: 'rgba(159, 159, 159, 0.38)'
      }
    },
    MuiInputLabel: {
      formControl: {
        left: '10px',
        color: fontColor,

        '&::after': {
          color: fontColor
        },

        focused: {
          color: fontColor
        },
      }
    },
    MuiInputBase: {
      root: {
        color: fontColor
      }
    },
    MuiPaper: {
      root: {
        borderColor: fontColor
      }
    },
    MuiToolbar: {
      root: {
        display: 'flex',
        flexDirection: 'row'
      }
    },
    MuiDivider: {
      root: {
        margin: '0 20px 20px 20px'
      }
    },
    MuiDialog: {
      root: {
        zIndex: '9999 !important'
      }
    },
  },

  // You can override the entire color palette if you want.
  // There are bundled alternatives, but you can also go 100% custom.
  // More info: https://material-ui.com/customization/themes/#palette
  palette: {
    divider: fontColor,
    primary: {
      main: mainColor,
      contrastText: fontColor
    },
    secondary: {
      main: secondaryColor
    },
    tertiary: {
      main: background
    },
    text: {
      primary: fontColor,
      secondary: gray
    }
  },

  // For a complete list of all fields you can override
  // you can check the definition of the default theme here:
  //   https://material-ui.com/customization/default-theme/
  //
  // NOTE: You're free to define custom properties on the theme too, but the
  // builtin components won't know about it, so those will be useful
  // only to custom styles.
  typography: {
    useNextVariants: true,
    fontFamily: '"Roboto Condensed", sans-serif',
    fontSize: '8em',
    color: fontColor,
  }
})
