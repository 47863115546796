import React from 'react'
import PropTypes from 'prop-types'
import { StyledText } from './styled'

const Text = ({ children }) => (
  <StyledText>{ children }</StyledText>
)

Text.propTypes = {
  children: PropTypes.string
}

export default Text